<template>
  <div :class="$style.home">
    <div :class="$style.content">
      <div :class="$style.title">
        <i class="ri-wallet-2-line" />
        收银台
      </div>
      <div v-if="data.order" :class="$style.box">
        <div :class="$style.left">
          <div :class="$style.stitle">订单详情</div>
          <div class="mb-10">
            支付金额：
            <span class="primary"> ￥{{ data.order.amount }}</span>
          </div>
          <div class="mb-10">
            来源渠道：
            <span class="primary">{{ data.order.order_type_name }}</span>
          </div>
          <div>
            订单编号：
            <span class="primary">{{ data.order.order_no }}</span>
          </div>
          <BjButton :class="$style.cannel" @click="onCannel()">
            <i class="ri-user-unfollow-line left" />
            取消支付
          </BjButton>
        </div>
        <div :class="$style.right">
          <div v-if="!type">
            <div :class="$style.stitle">选择支付方式</div>
            <div v-if="data.payment.corporate.is_show" :class="$style.item" @click="onCorporate()">
              <img :class="$style.cover" :src="oss + '/manage/material/public/cashier/account.png'" />
              <div :class="$style.name">
                <div style="color: #1470df">对公转账</div>
                <div :class="$style.desc">使用对公账户转账的形式支付</div>
              </div>
              <i :class="$style.icon" class="ri-arrow-right-s-fill" />
            </div>

            <div v-if="data.payment.wx.is_show" :class="$style.item" @click="onWx()">
              <img :class="$style.cover" :src="oss + '/manage/material/public/cashier/wechat.png'" />
              <div :class="$style.name">
                <div style="color: #23af41">微信支付</div>
                <div :class="$style.desc">使用微信APP扫码支付</div>
              </div>
              <i class="ri-arrow-right-s-fill" />
            </div>

            <div v-if="data.payment.alipay.is_show" :class="$style.item">
              <img :class="$style.cover" :src="oss + '/manage/material/public/cashier/alipay.png'" />
              <div :class="$style.name">
                <div style="color: #1296db">支付宝支付</div>
                <div :class="$style.desc">使用支付宝APP扫码支付</div>
              </div>
              <i class="ri-arrow-right-s-fill" />
            </div>

            <div v-if="data.payment.balance.is_show" :class="$style.item" @click="onBalance()">
              <img :class="$style.cover" :src="oss + '/manage/material/public/cashier/balance.png'" />
              <div :class="$style.name">
                <div style="color: #ff9502">余额支付</div>
                <div :class="$style.desc">
                  余额：
                  <span :class="$style.price">￥{{ data.payment.balance.attach.balance }} </span>
                  使用余额进行支付
                </div>
              </div>
              <i class="ri-arrow-right-s-fill" />
            </div>
          </div>

          <div v-if="type === 'corporate'" :class="$style.corporate">
            <div :class="$style.title">
              <i class="ri-arrow-left-line" @click="onBack()" />
              对公转账
            </div>
            <div :class="$style.tip">请将订购费用转至以下对公账户</div>
            <div :class="$style.info">
              <div :class="$style.label">
                账户名称 <span :class="$style.value">{{ data.payment.corporate.attach.account_name }}</span>
              </div>
              <div :class="$style.label">
                开户银行 <span :class="$style.value">{{ data.payment.corporate.attach.account_bank }}</span>
              </div>
              <div :class="$style.label">
                银行账户 <span :class="$style.value">{{ data.payment.corporate.attach.account }}</span>
              </div>
              <div :class="$style.line" />
              <div :class="$style.label">
                转账金额 <span :class="$style.value">{{ data.payment.corporate.attach.amount }}</span>
              </div>
              <div :class="$style.label">
                转账说明 <span :class="$style.value">{{ data.payment.corporate.attach.remark }}</span>
              </div>
              <div :class="$style.line" />
              <div :class="$style.btns">
                <div :class="$style.btn" @click="copy()">
                  <i class="ri-file-copy-line" />
                  复制账户信息
                </div>
                <div :class="$style.btn">
                  <i class="ri-customer-service-line" />
                  联系客服
                </div>
              </div>
            </div>
            <BjButton type="primary" block @click="onCorporateSubmit()">我已转账</BjButton>
            <div :class="$style.tip" class="text-center">我们会在收到您的转账后 24 小时内为你开通相关业务</div>
          </div>

          <div v-if="type === 'wx'" :class="$style.wx">
            <div :class="$style.title">
              <i class="ri-arrow-left-line" @click="onBack()" />
              微信支付
            </div>
            <div :class="$style.code">
              <img :src="info.pay_qr_code" />
            </div>
            <div :class="$style.text">
              <i class="ri-qr-scan-2-line" />
              <span>请使用微信扫码支付</span>
            </div>
          </div>

          <div v-if="type === 'success'" :class="$style.success">
            <img :src="oss + '/manage/material/public/cashier/success.png'" />
            <div :class="$style.title">订单支付成功</div>
            <div :class="$style.text">
              <span class="primary">5</span>
              秒后将返回来源渠道
            </div>
          </div>

          <div v-if="type === 'error'" :class="$style.success">
            <img :src="oss + '/manage/material/public/cashier/notice.png'" />
            <div :class="$style.title">订单支付失败</div>
          </div>

          <div v-if="type === 'balance'" :class="$style.balance">
            <div :class="$style.title">
              <i class="ri-arrow-left-line" @click="onBack()" />
              余额支付
            </div>
            <div :class="$style.vilidate">
              <div :class="$style.img">
                <i class="ri-shield-flash-line" />
              </div>
              <div :class="$style.vilidateContent" @click="onValidate()">
                <div :class="$style.name">
                  安全验证
                  <BjTag :class="$style.tag" :type="valid_key ? 'primary' : 'default'">{{
                    valid_key ? '已验证' : '未验证'
                  }}</BjTag>
                </div>
                <div :class="$style.text">当前操作需要安全验证，请立即验证！</div>
              </div>
            </div>
            <BjButton type="primary" block @click="onBalanceSubmit()">确认支付</BjButton>
          </div>

          <div v-if="type === 'group'" :class="$style.group">
            <div :class="$style.title">
              <i class="ri-arrow-left-line" @click="onBack()" />
              余额支付
            </div>
            <div class="tip">当前账户余额不足，需要使用组合支付。</div>
            <div :class="$style.num">
              使用余额支付
              <span class="primary">￥{{ data.payment.balance.attach.balance }}</span>
            </div>
            <div :class="$style.vilidate">
              <div :class="$style.img">
                <i class="ri-shield-flash-line" />
              </div>
              <div :class="$style.vilidateContent" @click="onValidate()">
                <div :class="$style.name">
                  安全验证
                  <BjTag :class="$style.tag" :type="valid_key ? 'primary' : 'default'">{{
                    valid_key ? '已验证' : '未验证'
                  }}</BjTag>
                </div>
                <div :class="$style.text">当前操作需要安全验证，请立即验证！</div>
              </div>
            </div>

            <div :class="$style.num">
              剩余
              <span class="primary">￥{{ data.payment.balance.attach.surplus_pay_amount }}</span>
              请选择以下方式支付
            </div>

            <div v-if="data.payment.wx.is_show" :class="$style.item" @click="onBalanceWx()">
              <img :class="$style.cover" :src="oss + '/manage/material/public/cashier/wechat.png'" />
              <div :class="$style.name">
                <div style="color: #23af41">微信支付</div>
                <div :class="$style.desc">使用微信APP扫码支付</div>
              </div>
              <i class="ri-arrow-right-s-fill" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <validateModal :visible.sync="visible.validate" @submit="validate" />
  </div>
</template>

<script>
import validateModal from '@/components/validateModal.vue'
import { centerService } from '@/service'

const service = new centerService()

export default {
  name: 'Home',
  components: {
    validateModal,
  },
  data() {
    return {
      data: {},
      type: '',
      info: {},
      valid_key: null,
      visible: {
        validate: false,
      },
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      try {
        const { data } = await service.cashier({
          order_type: Number(this.$route.query.order_type),
          order_id: this.$route.query.order_id,
        })
        this.data = data
      } catch (e) {}
    },
    onCannel() {
      this.$router.push({
        name: 'centerUserOrderManage',
      })
    },
    copy() {
      const input = document.createElement('textarea')
      document.body.appendChild(input)
      input.value = `账户名称 ${this.data.payment.corporate.attach.account_name}\n开户银行 ${this.data.payment.corporate.attach.account_bank} \n银行账号 ${this.data.payment.corporate.attach.account}`
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$message.success('复制成功')
    },
    onBack() {
      this.type = null
    },
    async onWx() {
      try {
        this.type = 'wx'
        const { data } = await service.unified({
          order_type: Number(this.$route.query.order_type),
          order_id: this.$route.query.order_id,
          payment: 'wx',
          attach_balance: 0,
        })
        this.info = data
        this.check()
      } catch (e) {}
    },
    async onBalanceWx() {
      if (!this.valid_key) {
        this.$message.error('请先安全验证')
        return
      }
      try {
        this.type = 'wx'
        const { data } = await service.unified({
          order_type: Number(this.$route.query.order_type),
          order_id: this.$route.query.order_id,
          payment: 'wx',
          attach_balance: 0,
        })
        this.info = data
        this.check()
      } catch (e) {}
    },
    onCorporate() {
      this.type = 'corporate'
    },
    async onCorporateSubmit() {
      await service.unified({
        order_type: Number(this.$route.query.order_type),
        order_id: this.$route.query.order_id,
        payment: 'corporate',
        attach_balance: 0,
      })
      this.$message.success('我们会在收到您的转账后 24 小时内为你开通相关业务')
      this.$router.push({
        name: 'centerUserOrderManage',
      })
    },
    check() {
      setTimeout(async () => {
        const { data } = await service.checkPay({
          check_key: this.info.check_key,
        })
        if (data.status === 'wait') {
          this.check()
        } else {
          if (data.status === 'done') {
            this.type = 'success'
          } else {
            this.type = 'error'
          }
        }
      }, 5000)
    },
    onBalance() {
      if (!this.data.payment.balance.attach.is_attach_assembly) {
        this.$message.error('余额不足')
        return
      }
      if (this.data.payment.balance.attach.is_attach_assembly === 2) {
        this.type = 'group'
      }
      if (this.data.payment.balance.attach.is_attach_assembly === 1) {
        this.type = 'balance'
      }
    },
    validate(e) {
      this.valid_key = e.valid_key
    },
    onValidate() {
      if (!this.valid_key) {
        this.visible.validate = true
      }
    },
    async onBalanceSubmit() {
      if (!this.valid_key) {
        this.$message.error('请先安全验证')
        return
      }
      await service.unified({
        order_type: Number(this.$route.query.order_type),
        order_id: this.$route.query.order_id,
        valid_key: this.valid_key,
        payment: 'balance',
        attach_balance: 0,
      })
      this.$message.success('支付成功')
      this.type = 'success'
      setTimeout(() => {
        this.$router.push({
          name: 'centerUserOrderManage',
        })
      }, 5000)
    },
  },
}
</script>

<style lang="less" module>
.home {
  position: relative;
  height: calc(100vh - 60px);
  width: 100%;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 800px;
    height: 546px;
    transform: translate(-50%, -50%);

    .title {
      font-size: 20px;
      margin-bottom: 20px;

      i {
        font-size: 24px;
        vertical-align: -5px;
      }
    }

    .box {
      background: #fff;
      border-radius: 6px;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
      height: 500px;
      padding: 20px;
      display: flex;

      .left {
        width: 400px;
        border-right: 1px solid #eee;
        font-size: 14px;
        position: relative;

        .cannel {
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      .right {
        flex: 1;
        padding-left: 20px;

        .item {
          width: 360px;
          height: 80px;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
          font-size: 14px;
          margin-bottom: 10px;
          border-radius: 6px;
          padding: 20px 10px 20px 20px;
          display: flex;
          align-items: center;
          cursor: pointer;

          .cover {
            width: 40px;
            height: 40px;
            margin-right: 14px;
          }

          .name {
            flex: 1;
          }

          .icon {
            font-size: 18px;
            color: #5c5c5c;
          }

          .desc {
            font-size: 12px;
            color: #5c5c5c;
            margin-top: 2px;
          }

          .price {
            color: @primary-color;
          }
        }
      }

      .stitle {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
  }

  .corporate {
    .title {
      font-size: 16px;
      color: #000;

      i {
        font-size: 24px;
        vertical-align: -5px;
      }
    }

    .tip {
      margin-bottom: 20px;
      font-size: 12px;
      margin-top: 10px;
      color: #5c5c5c;
    }

    .info {
      font-size: 14px;

      .value {
        margin-left: 20px;
        color: @primary-color;
      }

      .label {
        margin-bottom: 10px;
      }
    }

    .line {
      width: 100%;
      border-top: 1px solid #eee;
      margin: 20px 0;
    }

    .btns {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .btn {
        cursor: pointer;

        i {
          font-size: 17px;
          margin-right: 4px;
        }
      }

      .btn:hover {
        color: @primary-color;
      }
    }
  }

  .wx {
    .title {
      font-size: 16px;
      color: #000;

      i {
        font-size: 24px;
        vertical-align: -5px;
      }
    }

    .code {
      text-align: center;
      padding-top: 100px;

      img {
        width: 168px;
        height: 168px;
        object-fit: cover;
        margin-bottom: 14px;
      }
    }

    .text {
      font-size: 12px;
      text-align: center;

      i {
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }

  .success {
    text-align: center;
    padding-top: 160px;

    img {
      object-fit: cover;
      width: 80px;
      height: 80px;
    }

    .title {
      font-size: 14px;
      color: #000;
      margin-top: 14px;
      margin-bottom: 10px;
    }

    .text {
      font-size: 12px;
      color: #5c5c5c;
    }
  }

  .balance {
    .title {
      font-size: 16px;
      color: #000;

      i {
        font-size: 24px;
        vertical-align: -5px;
      }
    }

    .vilidate {
      cursor: pointer;
      margin-top: 100px;
      margin-bottom: 30px;
      width: 100%;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      padding: 14px;
      display: flex;

      .img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: #fafafa;
        text-align: center;
        line-height: 50px;
        margin-right: 14px;

        i {
          font-size: 24px;
        }
      }
      &-content {
        flex: 1;
      }
      .tag {
        float: right;
        font-size: 13px;
        color: #5c5c5c;
      }

      .name {
        font-size: 16px;
      }

      .text {
        margin-top: 10px;
        font-size: 12px;
        color: #5c5c5c;
      }
    }
  }

  .group {
    .title {
      font-size: 16px;
      color: #000;

      i {
        font-size: 24px;
        vertical-align: -5px;
      }
    }

    .vilidate {
      cursor: pointer;
      margin-bottom: 20px;
      width: 100%;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      padding: 14px;
      display: flex;

      .img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: #fafafa;
        text-align: center;
        line-height: 50px;
        margin-right: 14px;

        i {
          font-size: 24px;
        }
      }

      &-content {
        flex: 1;
      }

      .tag {
        float: right;
        font-size: 13px;
        color: #5c5c5c;
      }

      .name {
        font-size: 16px;
      }

      .text {
        margin-top: 10px;
        font-size: 12px;
        color: #5c5c5c;
      }
    }

    .num {
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 14px;
    }
  }
}
</style>
