<template>
  <bj-modal
    :class="$style.modal"
    title="安全验证"
    :visible="visible"
    :body-style="{ height: '400px' }"
    @cancel="cancel"
  >
    <div :class="$style.tip">
      当前操作为系统重要操作，需要通过安全验证后再进行相关操作。安全验证时效性有时间限制，请在10分钟内完成操作。
    </div>
    <BjForm>
      <a-row class="m-20">
        <a-col :class="$style.stitle" span="6">
          <span class="required">*</span>
          验证类型
        </a-col>
        <a-col span="18">
          <a-radio-group v-model="param.type">
            <a-radio :disabled="!user.email" :value="0"> 邮箱验证码 </a-radio>
            <a-radio :disabled="!user.telephone" :value="1"> 手机验证码 </a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <BjInput
        v-model="param.code"
        v-bind="layout"
        rules="required"
        label="验证码"
        label-align="left"
        :placeholder="param.type === 0 ? '请输入你绑定邮箱收到的验证码' : '请输入你绑定手机收到的验证码'"
      >
        <span v-if="!finish" slot="suffix" class="tag cursor" @click="getCode()">{{
          time === 0 ? '没收到？重新发送' : '获取验证码'
        }}</span>
        <span v-else slot="suffix" class="tag">{{ time }}秒后可重新获取</span>
      </BjInput>
    </BjForm>
    <template #footer>
      <BjButton class="btn-default" @click="cancel">取消</BjButton>
      <BjButton type="primary" @click="onSubmit()">完成验证</BjButton>
    </template>
  </bj-modal>
</template>

<script>
import { mapState } from 'vuex'

import { systemInfoService } from '@/service'

const service = new systemInfoService()

export default {
  name: 'validateModal',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      param: {
        code: null,
        type: 0,
      },
      time: 60,
      valid_key: null,
      finish: false,
      loading: false,
      timer: null,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    visible(val) {
      if (val) {
        if (!this.user.email) {
          this.param.type = 1
        } else {
          this.param.type = 0
        }
      } else {
        this.init()
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('update:visible', false)
    },
    async onSubmit() {
      try {
        const { data } = await service.checkValidCode({
          account_type: this.param.type ? 'telephone' : 'email',
          code: this.param.code,
        })
        this.$emit('submit', { ...this.params, valid_key: data.valid_key })
        this.cancel()
      } catch (e) {}
    },
    async getCode() {
      if (this.loading) return
      try {
        this.loading = true
        const { data } = await service.getValidCode({
          template: 'valid_account',
          username: this.param.type ? this.user.telephone : this.user.email,
        })
        this.finish = true
        this.time = 60
        this.timer = setInterval(() => {
          if (this.time === 0) {
            this.finish = false
            clearInterval(this.timer)
          } else {
            this.time--
          }
        }, 1000)
        this.$message.success('带有验证码的信息已经通过你填写的邮箱或手机号码发送给你，注意查收。')
        this.valid_key = data
        setTimeout(() => {
          this.loading = false
        })
      } catch (e) {
        setTimeout(() => {
          this.loading = false
        })
      }
    },
    init() {
      this.param = {
        code: null,
        type: 0,
      }
      clearInterval(this.timer)
      this.time = 60
      this.finish = false
    },
  },
}
</script>

<style lang="less" module>
.modal {
  .tip {
    padding: 10px;
    color: #5c5c5c;
    font-size: 12px;
    background: #fafafa;
  }

  .stitle {
    color: #000;
    font-size: 14px;
  }
}
</style>
